import Modal from './modules/modal.js'; //quite
import objectFitImages from './modules/objectfit.js'; //quite
import mobileMenu from './modules/mobile-menu.js'; //quite

import * as Swiper from './extern/swiper.js'; //quite

//Touch sniffer
if ('ontouchstart' in document.documentElement) {
	document.querySelector('html').classList.add('is-touch');
}

// Init functions on pageload
function init() {
	objectFitImages();
	mobileMenu();
	new Modal();
}

jQuery(document).ready(function ($) {
	init();
});
